exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-categories-js": () => import("./../../../src/pages/categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-create-js": () => import("./../../../src/pages/create.js" /* webpackChunkName: "component---src-pages-create-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-purpose-js": () => import("./../../../src/pages/purpose.js" /* webpackChunkName: "component---src-pages-purpose-js" */),
  "component---src-pages-standards-js": () => import("./../../../src/pages/standards.js" /* webpackChunkName: "component---src-pages-standards-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-standard-html-js": () => import("./../../../src/templates/standardHtml.js" /* webpackChunkName: "component---src-templates-standard-html-js" */),
  "component---src-templates-standard-js": () => import("./../../../src/templates/standard.js" /* webpackChunkName: "component---src-templates-standard-js" */)
}

